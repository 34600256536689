<template>
    <div>
        <v-app-bar
            id="home-app-bar"
            app
            elevation="1"
            elevate-on-scroll
            height="80"
        >
            <v-row no-gutters justify="center">
                <v-col lg="9" cols="12">
                    <v-row no-gutters align="center">
                        <base-img
                            :src="require('@/assets/logo-mark-sm.png')"
                            class="mr-3 hidden-xs-only"
                            contain
                            max-width="64"
                            width="100%"
                        />

                        <base-img
                            :src="require(`@/assets/apply-logo.png`)"
                            contain
                            max-width="250"
                            width="100%"
                        />

                        <v-spacer />
                        <v-btn color="primary" :href="loginUrl">Login</v-btn>

                        <div>
                            <v-tabs
                                v-if="false"
                                class="hidden-sm-and-down"
                                optional
                                background-color="transparent"
                            >
                                <v-tab
                                    v-for="(name, i) in items"
                                    :key="i"
                                    :to="{ name }"
                                    :exact="name === 'Home'"
                                    :ripple="false"
                                    class="font-weight-bold"
                                    min-width="96"
                                    text
                                >
                                    {{ name }}
                                </v-tab>
                            </v-tabs>
                        </div>

                        <v-app-bar-nav-icon
                            v-if="false"
                            class="hidden-md-and-up"
                            @click="drawer = !drawer"
                        />
                    </v-row>
                </v-col>
            </v-row>
        </v-app-bar>

        <home-drawer v-if="false" v-model="drawer" :items="items" />
    </div>
</template>

<script>
export default {
    name: "HomeAppBar",

    components: {
        HomeDrawer: () => import("./Drawer"),
    },

    data: () => ({
        drawer: null,
        items: [
            "Home",
            "About",
            "Marketing",
            "Gallery",
            "Pricing",
            "News",
            "Contact",
        ],
        loginUrl: process.env.VUE_APP_LOGIN_URL,
    }),
    mounted() {},
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

    .v-tab
    &::before
      display: none
</style>
